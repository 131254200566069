import http from "../http-common"
//http is basically an alias for an axios object
class TuneDataService {
  getAll() {
    return http.get("/tunes");
  }

  get(id) {
    return http.get(`/tunes/${id}`);
  }

  create(data) {
    return http.post("/tunes", data);
  }

  update(id, data) {
    return http.put(`/tunes/${id}`, data);
  }

  findByTitle(title) {
    return http.get(`/tunes?title=${title}`);
  }
}
export default TuneDataService;
