//This is by no means a pretty application, and it might not even be secure...
//However I needed a database for my app Tune Tracker, so I made a rough version to build from.
import './App.css';
import List from './components/List.tsx';
import Edit from './components/Edit.tsx';
import TuneDataService from './services/tune.service';
import ComposerDataService from './services/composer.service';
import React from "react";
//import axios from './http-common';
let tds = new TuneDataService();
let cds = new ComposerDataService();
function App() {
  const [tunes, setTunes] = React.useState(null);
  const [composers, setComposers] = React.useState(null);
  const [currentItem, setCurrentItem] = React.useState({});
  const [editing, setEditing] = React.useState(false);
  const [isComposer, setIsComposer] = React.useState(false);
  const reloadTunes = (() => {
    tds.getAll().then((response) => {
      setTunes(response.data);
    });
  });
  const reloadComposers = (() => {
    cds.getAll().then((response) => {
      setComposers(response.data);
    });
  });
  React.useEffect(() => {
  //axios.defaults.headers.common['Content-Type'] = "application/json";
  //axios.defaults.headers.common['Access-Control-Allow-Origin'] = "tunetracer.jhilla.org";
    reloadTunes();
    reloadComposers();
  //axios.interceptors.response.use(function (response) {
  //  return response;
  //}, function (error) {
  //  return Promise.reject(error);
  //});
  }, []);
  if(tunes === null){
    return (
      <p>
      <meta httpEquiv="Content-Security-Policy" content="upgrade-insecure-requests"/>
      "Loading... (Refresh after 3 seconds)"
      </p>
    );
  }
  return (
    <header className='App-header w-screen overflow-x-scroll'>
    <meta httpEquiv="Content-Security-Policy" content="upgrade-insecure-requests"/>
      <div>
        <button className='m-3 p-2 bg-slate-700 text-lg' onClick={()=>{
          setCurrentItem({});
          setIsComposer(false);
          setEditing(true);
        }}>
          Add tune
        </button>
        <button className='m-3 p-2 bg-slate-700 text-lg' onClick={()=>{
          setCurrentItem({});
          setIsComposer(true);
          setEditing(true);
        }}>
          Add composer
        </button>
      </div>
      <div className='flex-row flex'>
        {composers && <List
          list={composers}
          setCurrentTune={setCurrentItem}
          setEditing={setEditing}
          setIsComposer={setIsComposer}
          isComposerList={true}/>}
        {tunes && <List list={tunes}
          setCurrentTune={setCurrentItem}
          setIsComposer={setIsComposer}
          isComposerList={false}
          setEditing={setEditing}/>}
        { editing && 
          <Edit data={currentItem}
            setEditing={setEditing}
            tuneDataService={tds}
            composerDataService={cds}
            isComposer={isComposer}
            composers={composers}
          /> 
        }
      <div/>
      </div>
    </header>
  );
}

export default App;
