import React from 'react';
type tune = {
  "title"?: string
  "bio"?: string
  "alternative_title"?: string
  "Composers"?: composer[]
  "form"?: string
  "notable_recordings"?: string[]
  "keys"?: string[]
  "styles"?: string[]
  "tempi"?: string[]
  "contrafacts"?: string[] // In the future, these could link to other tunes
  "id"?: number
  "year"?: number
  "played_at"?: string[]
}
type composer = {
  "name": string
  "id": number
  "birth": string
  "death": string
  "bio": string
}
export default function List({list, setCurrentTune, setEditing, setIsComposer, isComposerList}:
                                 {list: tune[] | composer[], setCurrentTune: Function, setEditing: Function, setIsComposer: Function, isComposerList: boolean}){
  if(isComposerList){
    (list as composer[]).sort( (item1, item2) => {
      if(item1.name < item2.name){
        return -1;
      }else{
        return 1;
      }
    })
  }else{
    (list as tune[]).sort( (item1, item2) => {
      if(!item1.title || !item2.title){
        return 0;
      }
      if(item1.title < item2.title){
        return -1;
      }else{
        return 1;
      }
    })
  }
  const listItems = list.map(
    (item: tune | composer) => 
      <div className='m-2' key={item.id}>
        <button className='bg-slate-900 justify-items-start' onClick={() => {
          setCurrentTune(item);
          setIsComposer(isComposerList);
          setEditing(true);
          let editForm = document.getElementById("edit-form");
          //THIS MIGHT NOT WORK
          editForm && (editForm as HTMLFormElement).reset();
        }}>
          <p className='text-2xl'>
            {isComposerList ? (item as composer).name : (item as tune).title}
          </p>
          <p className='text-base text-left'>
          {!isComposerList && ((item as tune).alternative_title ? (item as tune).alternative_title : "No alternative title")}
          </p>
          <p className='text-base text-left'>
            {!isComposerList && ((item as tune).Composers ? (item as tune).Composers.map(comp => comp.name).join(", ")  : "No alternative title")}
          </p>
        </button>
      </div>
  );
  return(
    <div className='m-1 h-screen overflow-auto'>
      {listItems}
    </div>
  );
}
// Other attributes to view
//        <p className='text-base text-left bg-cyan-800'>
//          {tn.bio ? tn.bio : "No bio provided"}
//        </p>
//        <p className='text-base text-left'>
//          {tn.year ? tn.year : "No year provided"}
//        </p>
//        <p className='text-base text-left bg-slate-800'>
//          {tn.form ? tn.form : "No form provided"}
//        </p>
